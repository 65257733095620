import { useCallback, useEffect, useRef } from 'react'

export const useDebouncedCallback = (
  cb: (() => void) | undefined,
  delay: number,
) => {
  // const [timer, setTimer] = useState<NodeJS.Timeout | null>(null)
  const timer = useRef<NodeJS.Timeout | null>(null)

  useEffect(
    () => () => {
      if (timer.current) clearTimeout(timer.current)
    },
    [timer],
  )

  return useCallback(() => {
    if (timer.current) clearTimeout(timer.current)
    timer.current = setTimeout(() => cb?.(), delay)
  }, [cb, delay])
}

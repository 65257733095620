import React from 'react'
import {
  Beta,
  IconArrowLeftDefault,
  IconArrowRightDefault,
} from '@mc/design-system'

export const Pagination = ({
  pageCountOffset = 0,
}: {
  pageCountOffset?: number
}) => {
  const { pages, page, prevPage, nextPage } = Beta.useCarousel()
  const isActive = (i: number) => page % pages === i

  const handleNextPage = () => {
    if (page + 1 < pages - pageCountOffset) {
      nextPage()
    }
  }

  return (
    <div className='d-flex justify-content-center mc-mt-6'>
      <div className='d-flex align-items-center flex-gap-2'>
        <button onClick={prevPage} className='mc-clickable'>
          <IconArrowLeftDefault />
        </button>
        {Array.from({ length: pages - pageCountOffset }).map((_, i) => (
          <div
            key={i}
            className='mc-mt-1'
            style={{
              width: 'var(--mc-scale-2)',
              height: 'var(--mc-scale-2)',
              borderRadius: 20,
              backgroundColor: isActive(i)
                ? 'white'
                : 'rgba(255, 255, 255, 0.3)',
            }}
          />
        ))}
        <button onClick={handleNextPage} className='mc-clickable'>
          <IconArrowRightDefault />
        </button>
      </div>
    </div>
  )
}

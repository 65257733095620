/* istanbul ignore file */
import * as React from 'react'
import type { SVGProps } from 'react'
import PropTypes from 'prop-types'

interface Props extends SVGProps<SVGSVGElement> {
  srText?: string
  size?: 'sm' | 'md' | 'lg' | 'scale-8' | 'scale-10' | number
  alt?: string
}
function IconArrowRightDefault({
  srText,
  size = 'md',
  className = '',
  ...props
}: Props) {
  const newClassName = `mc-icon mc-icon--${size} ${className}`
  return (
    <>
      {!!srText && <span className='mc-sr-only'>{srText}</span>}
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={24}
        height={25}
        fill='none'
        viewBox='0 0 24 24'
        aria-hidden='true'
        role='img'
        className={newClassName}
        {...props}
      >
        <path
          fill='currentColor'
          fillRule='evenodd'
          d='M12.87 2.825a1.094 1.094 0 0 0-1.557 0 1.12 1.12 0 0 0 0 1.572l6.961 7.025H3.101c-.608 0-1.101.498-1.101 1.111 0 .614.493 1.112 1.1 1.112h15.108l-6.895 6.958a1.12 1.12 0 0 0 0 1.572c.43.433 1.127.433 1.557 0l8.808-8.89c.43-.433.43-1.137 0-1.57z'
          clipRule='evenodd'
        />
      </svg>
    </>
  )
}
IconArrowRightDefault.propTypes = {
  srText: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
} as PropTypes.ValidationMap<Props>
export default IconArrowRightDefault

import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
  useMemo,
} from 'react'
import { compact } from 'lodash'

import { useTranslation, withI18nContext } from '@mc/i18n'
import i18nInstance from '../../i18n/i18nInstance'

import { Button, ButtonProps, renderChildren } from '../../components'

import styles from './Carousel.module.scss'
import { useDebouncedCallback } from './useDebouncedCallback'
import {
  buildScroller,
  getHorizontalPadding,
  getInnerWidth,
  getScrollWidth,
} from './scrolling'

// ignoring this lint error, since using `HTMLElement` _as a type_ should be fine for SSR
// eslint-disable-next-line ssr-friendly/no-dom-globals-in-module-scope
type Container = HTMLElement

type CarouselContextType = {
  srLabel: string
  position: number
  initialPage: number
  setPosition: React.Dispatch<React.SetStateAction<number>>
  page: number
  scrollCount: number
  loops: boolean
  tileCount: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  nextPage: () => void
  prevPage: () => void
  pages: number
  setPages: React.Dispatch<React.SetStateAction<number>>
  start: boolean
  setStart: React.Dispatch<React.SetStateAction<boolean>>
  end: boolean
  setEnd: React.Dispatch<React.SetStateAction<boolean>>
  container: Container | null
  setContainer: React.Dispatch<React.SetStateAction<Container | null>>
  firstVisibleIndex: () => number
}

type WithChild = {
  children?: React.ReactNode
}

type WithChildren = {
  children?: React.ReactNode | React.ReactNode[]
}

type As = React.ElementType | React.ComponentType

type WithAs = {
  as?: As
}

const CarouselContext = createContext<CarouselContextType | null>(null)

export const useCarousel = () => {
  const ctx = useContext(CarouselContext)
  if (!ctx) {
    throw new Error('Cannot use CarouselContext outside of provider')
  }
  return ctx
}

// event callbacks should always be allowed to be undefined
type OnEventCallback = (() => void) | undefined

export type CarouselProps = {
  srLabel: string // required for a11y, describes the content within the carousel
  initialPage?: number
  onClickNext?: OnEventCallback
  onClickPrev?: OnEventCallback
  /**
   * Whether to loop the carousel infinitely
   */
  loops?: boolean
  /**
   * Number of children by which to advance the carousel via next/prev buttons
   *
   * 0 or undefined indicates a "full page" according to current screen width
   */
  scrollCount?: number
} & WithChildren

export const Carousel = ({
  srLabel = 'carousel',
  initialPage = 0,
  children,
  onClickNext,
  onClickPrev,
  loops = false,
  // scrollCount = 0 indicates a "full page" according to current screen width
  scrollCount = 0,
}: CarouselProps) => {
  const [position, setPosition] = useState(0)
  const [page, setPage] = useState(0)
  const [pages, setPages] = useState(0)
  const [start, setStart] = useState(true)
  const [end, setEnd] = useState(false)
  const [container, setContainer] = useState<Container | null>(null)

  const paginator = useMemo(
    () => buildScroller(loops, container, scrollCount),
    [loops, container, scrollCount],
  )

  const nextPage = () => {
    onClickNext?.()

    paginator.forward()
  }

  const prevPage = () => {
    onClickPrev?.()

    paginator.backward()
  }

  return (
    <CarouselContext.Provider
      value={{
        srLabel,
        tileCount: paginator.tileCount,
        position,
        initialPage,
        setPosition,
        loops,
        scrollCount,
        page,
        setPage,
        nextPage,
        prevPage,
        pages,
        setPages,
        start,
        setStart,
        end,
        setEnd,
        container,
        setContainer,
        firstVisibleIndex: paginator.firstVisibleIndex,
      }}
    >
      <div>{children}</div>
    </CarouselContext.Provider>
  )
}

type CarouselContentProps = {
  className?: string
  overflow?: boolean
  onPositionChanged?: (direction: 'right' | 'left') => void
  onItemChanged?: (index: number) => void
  scrollSnapAlign?: 'start' | 'center' | 'end' | 'none'
  style?: React.CSSProperties & Record<string, string>
} & WithChildren &
  WithAs

// scrolling happens continuously at 16ms intervals
// this should be high enough to prevent calling during the animation,
// but low enough to be responsive
const DEBOUNCE = 50

export const CarouselContent = ({
  as: Component = 'section',
  children,
  className = '',
  overflow = false,
  onPositionChanged,
  onItemChanged,
  scrollSnapAlign = 'start',
  ...restProps
}: CarouselContentProps) => {
  const {
    srLabel: label,
    position,
    initialPage,
    scrollCount,
    tileCount,
    setPosition,
    setPage,
    setPages,
    setStart,
    setEnd,
    container,
    setContainer,
    firstVisibleIndex,
    loops,
  } = useCarousel()

  const delayedPositionChanged = useDebouncedCallback(
    () =>
      onPositionChanged?.(
        position > (container?.scrollLeft ?? 0) ? 'left' : 'right',
      ),
    DEBOUNCE,
  )

  const delayedItemChanged = useDebouncedCallback(() => {
    if (!container) return
    const idx = firstVisibleIndex()

    onItemChanged?.(idx)
  }, DEBOUNCE)

  useEffect(() => {
    const setProperties = () => {
      if (container) {
        const idx = firstVisibleIndex()

        // we don't want to include overflow tiles into the page calculations
        const padding = getHorizontalPadding(container)
        const innerWidth = container.clientWidth - padding
        const scrollWidth = container.scrollWidth - padding
        // ceil to give correct whole number rounding when page calc is not exact
        const pagesByWidth = Math.ceil(scrollWidth / innerWidth)
        const pages =
          scrollCount > 0 && pagesByWidth > 1
            ? Math.ceil(tileCount / scrollCount)
            : pagesByWidth
        const scrollable = scrollWidth - innerWidth
        const scrolled = position / scrollable
        const page =
          scrollCount > 0
            ? Math.floor(idx / scrollCount)
            : Math.round(scrolled * (pages - 1))

        setPage(page)
        setPages(pages)
        setStart(position === 0)

        const currentEnd = Math.ceil(position + innerWidth)
        setEnd(currentEnd >= scrollWidth)

        delayedItemChanged()
      }
    }

    setProperties()

    window.addEventListener('resize', setProperties)

    return () => {
      window.removeEventListener('resize', setProperties)
    }
  }, [
    children,
    container,
    position,
    scrollCount,
    setPage,
    setPages,
    setEnd,
    setStart,
    onItemChanged,
    delayedItemChanged,
    firstVisibleIndex,
    tileCount,
    loops,
  ])

  useEffect(() => {
    if (container?.scroll) {
      const innerWidth = getInnerWidth(container)
      const scrollWidth = getScrollWidth(container)

      if (loops) {
        container.scroll({
          left: scrollWidth / 2,
          behavior: 'instant',
        })
      }
      if (initialPage > 0) {
        container.scroll({
          left: initialPage * innerWidth,
          behavior: 'auto',
        })
      }
    }
  }, [container, initialPage, loops, tileCount])

  useEffect(() => {
    if (container) {
      Array.from(container.children).forEach((child, i) => {
        child.setAttribute('role', 'group')

        const descriptiveLabel = child.getAttribute('aria-label')
          ? `${child.getAttribute('aria-label')}, `
          : ''

        child.setAttribute(
          'aria-label',
          `${descriptiveLabel}${
            (i % tileCount) + 1
          } of ${tileCount} in ${label}`,
        )
      })
    }
  }, [container, label, tileCount])

  const handleScroll: React.EventHandler<React.UIEvent<Container>> = () => {
    if (!container) {
      return
    }
    setPosition(container.scrollLeft)
    delayedPositionChanged()
  }

  return (
    <Component
      aria-label={label}
      {...restProps}
      className={compact([
        styles.content,
        className,
        scrollSnapAlign && scrollSnapAlign !== 'none'
          ? styles[
              `scrollSnapAlign${
                scrollSnapAlign.charAt(0).toUpperCase() +
                scrollSnapAlign.slice(1)
              }`
            ]
          : '',
        overflow ? styles.overflow : '',
      ]).join(' ')}
      onScroll={handleScroll}
      ref={setContainer}
    >
      {children}
    </Component>
  )
}

export const CarouselItem = ({ children }: WithChild) => {
  const { container } = useCarousel()
  const childRef = useRef<HTMLDivElement>(null)
  const [isIntersecting, setIsIntersecting] = useState(false)

  useEffect(() => {
    if (container && childRef.current) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            setIsIntersecting(entry.isIntersecting)
          })
        },
        { root: container },
      )

      observer.observe(childRef.current)

      return () => {
        observer.disconnect()
      }
    }

    return () => {}
  }, [container, childRef])

  return (
    <>
      {renderChildren(children, {
        ref: childRef,
        tabIndex: isIntersecting ? 0 : -1,
        'aria-hidden': !isIntersecting,
      })}
    </>
  )
}

export const CarouselInteractive = ({ children }: WithChild) => {
  const { container } = useCarousel()
  const childRef = useRef<HTMLDivElement>(null)
  const [isIntersecting, setIsIntersecting] = useState(false)

  useEffect(() => {
    if (container && childRef.current) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            setIsIntersecting(entry.isIntersecting)
          })
        },
        { root: container },
      )

      observer.observe(childRef.current)
    }
  }, [container, childRef])

  return (
    <>
      {renderChildren(children, {
        ref: childRef,
        tabIndex: isIntersecting ? 0 : -1,
        'aria-hidden': !isIntersecting,
      })}
    </>
  )
}

export const CarouselArrows = ({ children }: WithChildren) => (
  <div className={styles.arrowsClassic}>{children}</div>
)

export const CarouselPrevious = withI18nContext(i18nInstance)((
  props: ButtonProps,
) => {
  const { t } = useTranslation('@mc/design-system')

  const { loops, pages, prevPage, start } = useCarousel()
  const handleClick = (e: React.MouseEvent) => {
    prevPage()

    props.onClick?.(e)
  }

  if (pages < 2) return <></>

  const disabled = start && !loops

  return (
    <Button
      {...props}
      className={[
        props.className,
        styles.arrow,
        styles.prev,
        disabled ? styles.disabled : '',
      ]
        .join(' ')
        .trim()}
      onClick={handleClick}
      disabled={disabled}
      aria-label={t('carousel.previousButtonAlt')}
    />
  )
})

export const CarouselNext = withI18nContext(i18nInstance)((
  props: ButtonProps,
) => {
  const { t } = useTranslation('@mc/design-system')

  const { loops, pages, end, nextPage } = useCarousel()
  const handleClick = (e: React.MouseEvent) => {
    nextPage()

    props.onClick?.(e)
  }

  if (pages < 2) return <></>

  const disabled = end && !loops

  return (
    <Button
      {...props}
      className={[
        props.className,
        styles.arrow,
        styles.next,
        disabled ? styles.disabled : '',
      ]
        .join(' ')
        .trim()}
      onClick={handleClick}
      disabled={disabled}
      aria-label={t('carousel.nextButtonAlt')}
    />
  )
})

export const CarouselPages = ({ className = '' }: { className?: string }) => {
  const { pages, page, container } = useCarousel()

  const isActive = (i: number) => page % pages === i
  const handleClick = (i: number) => () =>
    container?.scroll({
      left: i * container.clientWidth,
      behavior: 'smooth',
    })

  if (pages < 2) return <></>

  return (
    <div className={`${styles.pages} ${className}`}>
      {Array.from({ length: pages }).map((_, i) => (
        <button
          key={i}
          className={[styles.page, isActive(i) ? styles.active : ''].join(' ')}
          onClick={handleClick(i)}
          tabIndex={0}
          aria-current={isActive(i)}
          aria-label={`Page ${i + 1} of ${pages}`}
        ></button>
      ))}
    </div>
  )
}

export const getHorizontalPadding = (el: HTMLElement) => {
  const computedStyle = getComputedStyle(el)

  const padding =
    parseFloat(computedStyle.paddingLeft) +
    parseFloat(computedStyle.paddingRight)

  return padding
}

export const getInnerWidth = (el: HTMLElement) => {
  const padding = getHorizontalPadding(el)

  // we don't want to include overflow tiles into the page calculations
  return el.clientWidth - padding
}

export const getScrollWidth = (el: HTMLElement) => {
  const padding = getHorizontalPadding(el)

  return el.scrollWidth - padding
}

function firstChildIndexWithOffsetLeft(
  container: HTMLElement,
  f: (offsetLeft: number) => boolean,
): number {
  return Array.from(container.children).findIndex((child) =>
    f((child as HTMLElement).offsetLeft),
  )
}

export function firstVisibleIndex(container: HTMLElement) {
  return firstChildIndexWithOffsetLeft(
    container,
    (offsetLeft) => offsetLeft >= container.scrollLeft,
  )
}

function forwardNextIndex(container: HTMLElement, scrollCount: number): number {
  if (scrollCount === 0) {
    const containerWidth = getInnerWidth(container)

    return firstChildIndexWithOffsetLeft(
      container,
      (offsetLeft) => offsetLeft >= container.scrollLeft + containerWidth,
    )
  } else {
    const idx = firstVisibleIndex(container)

    return Math.min(idx + scrollCount, container.children.length - 1)
  }
}

function backwardNextIndex(
  container: HTMLElement,
  scrollCount: number,
): number {
  if (scrollCount === 0) {
    const containerWidth = getInnerWidth(container)

    return firstChildIndexWithOffsetLeft(
      container,
      (offsetLeft) => offsetLeft >= container.scrollLeft - containerWidth,
    )
  } else {
    const idx = firstVisibleIndex(container)

    return Math.max(idx - scrollCount, 0)
  }
}

function goToIndex(
  container: HTMLElement,
  idx: number,
  behavior: 'smooth' | 'instant' = 'smooth',
) {
  const computedContainerStyle = getComputedStyle(container)

  const el = container.children.item(idx) as HTMLElement
  if (!el) return

  container.scrollTo({
    left: el.offsetLeft - parseFloat(computedContainerStyle.paddingLeft),
    behavior,
  })
}

export function goForward(container: HTMLElement, scrollCount: number) {
  goToIndex(container, forwardNextIndex(container, scrollCount))
}

export function goBackward(container: HTMLElement, scrollCount: number) {
  goToIndex(container, backwardNextIndex(container, scrollCount))
}

export function forwardLoop(container: HTMLElement, scrollCount: number) {
  const currentFirstIndex = firstVisibleIndex(container)
  const uniqueTileCount = container.children.length / 2

  if (currentFirstIndex >= uniqueTileCount) {
    goToIndex(container, currentFirstIndex - uniqueTileCount, 'instant')
  }

  goForward(container, scrollCount)
}

export function backwardLoop(container: HTMLElement, scrollCount: number) {
  const currentFirstIndex = firstVisibleIndex(container)
  const uniqueTileCount = container.children.length / 2

  if (currentFirstIndex <= 0) {
    goToIndex(container, currentFirstIndex + uniqueTileCount, 'instant')
  }

  goBackward(container, scrollCount)
}

interface Scroller {
  forward: () => void
  backward: () => void
  firstVisibleIndex: () => number
  tileCount: number
}

export function buildScroller(
  loops: boolean,
  container: HTMLElement | null,
  scrollCount: number,
): Scroller {
  if (loops) {
    const uniqueTileCount = container ? container.children.length / 2 : 0

    return {
      forward: () => container && forwardLoop(container, scrollCount),
      backward: () => container && backwardLoop(container, scrollCount),
      firstVisibleIndex: () => {
        if (!container) {
          return 0
        }

        const idx = firstVisibleIndex(container)
        return idx > uniqueTileCount ? idx - uniqueTileCount : idx
      },
      tileCount: uniqueTileCount,
    }
  } else {
    return {
      forward: () => container && goForward(container, scrollCount),
      backward: () => container && goBackward(container, scrollCount),
      firstVisibleIndex: () => {
        if (!container) {
          return 0
        }

        return firstVisibleIndex(container)
      },
      tileCount: container?.children.length ?? 0,
    }
  }
}
